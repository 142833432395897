import vue from "vue";

export const fetchCartItem = async (payload) =>
  await vue.axios.post("/cart/cart/view", payload);

export const addToCartService = async (payload) =>
  await vue.axios.post("/cart/cart/add", payload);

export const removeCartService = async (payload) =>
  await vue.axios.post("/cart/cart/remove", payload);

export const finalCheckout = async (payload) => {
  const res = await vue.axios.post("/checkout/checkout/v2/confirm", payload);
  return res;
};
export const finalTransactionBenefits = async (payload) =>
  await vue.axios.post("/order/api/v1/order/reward-estimate", payload);

export const fetchCoupon = async () =>
  await vue.axios.get("/promo/promotion/get/user/tenant/all");

export const clearCart = async () => await vue.axios.get("/cart/cart/empty");

export const fetchBankDetails = async (payload) =>
  await vue.axios.get(`/payments/payout/cashfree/get-beneficiary/${payload}`);

export const createOrder = async (payload) => {
  const res = await vue.axios.post("/order/api/v2/order/create", payload);
  return res;
};
export const orderValidation = async (payload) => {
  const res = await vue.axios.post("/order/api/v2/order/verify", payload);
  return res;
};
export const getPartnerInfo = async (payload) => {
  const res = await vue.axios.get(
    `/cap/users/partner/get-preferences/${payload}`
  );
  return res.data.response;
};
