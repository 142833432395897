import moduleConfig from "./search.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./search.module"),
    children: [
      {
        path: ':id',
        name: 'SearchProduct',
        component: () => import("./views/search"),
      },
    ],
  },
];
