import vue from "vue";

export const fetchAddress = async () =>
  await vue.axios.get("cap/users/customer/list-address");

export const addNewAddress = async (payload) =>
  await vue.axios.post("/cap/users/customer/add-address", payload);

export const deleteAddressApi = async (payload) =>
  await vue.axios.delete(`/cap/users/customer/delete-address/${payload}`);

export const editNewAddress = async (payload) =>
  await vue.axios.put(
    `/cap/users/customer/update-address/${payload.id}`,
    payload.data
  );
