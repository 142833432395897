import locationEvent from "./events/location";

export default {
  namespaced: true,
  state: {
    currentPosition: null,
  },
  getters: {
    getCurrentPosition: (state) => {
      return state.currentPosition;
    },
  },
  actions: {
    actionCurrentPosition: (context, payload) => {
      context.commit("setCurrentPosition", payload);
    },
  },
  mutations: {
    setCurrentPosition: (state, payload) => {
      locationEvent.listener.emit(locationEvent.channels.locationChanged, payload)
      state.currentPosition = payload;
    },
  },
};
