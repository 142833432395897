import { fetchAllItems, fetchMarketList } from "./services";
import { structureItemData } from "/src/helpers/items";
export default {
  namespaced: true,
  state: {
    itemSuggestions: {},
    trendingItems: {},
    isLoading: false,
    defaultLocation: {
      City: "",
      Country: "",
      State: "",
      zip: "",
    },
    marketList: null,
  },
  getters: {
    getItemSuggestions(state) {
      return (id) => state.itemSuggestions[id];
    },
    getTrendingItems(state) {
      return (id) => state.trendingItems[id];
    },
  },
  actions: {
    fetchAllItems: async ({ commit, state }, market) => {
      try {
        if (
          !state.itemSuggestions[market.path] &&
          !state.trendingItems[market.path]
        ) {
          commit("setItemSuggestions", {
            key: market.path,
            value: [],
          });
          commit("setTrendingItems", {
            key: market.path,
            value: [],
          });
          const result = await fetchAllItems({
            market: [market.path],
            page: { page: 1, itemsPerPage: 20 },
          });
          commit("setItemSuggestions", {
            key: market.path,
            value: structureItemData(result.itemSuggestions),
          });
          commit("setTrendingItems", {
            key: market.path,
            value: structureItemData(result.trendingItems),
          });
        }
      } catch (e) {
        //
      }
    },

    fetchMarketList: async ({ commit, state }, market, refresh = false) => {
      try {
        if (!state.marketList || refresh) {
          commit("setMarketList", null);
        }
        const City = [market.location];
        const data = await fetchMarketList(City);
        const result = data.filter((x) => x.isMarket);
        commit("setMarketList", result);
      } catch (e) {
        //
      }
    },

    setLocation: ({ commit, dispatch }, data) => {
      commit("setLocationData", data);
      dispatch("fetchAllItems", true);
      dispatch("fetchMarketList", true);
    },
  },
  mutations: {
    setItemSuggestions: (state, data) => {
      state.itemSuggestions = {
        ...state.itemSuggestions,
        [data.key]: data.value,
      };
    },
    setTrendingItems: (state, data) => {
      const result = data.value.filter((each) => each.Market === data.key);
      state.trendingItems = {
        ...state.trendingItems,
        [data.key]: result,
      };
    },

    setLocationData: (state, data) => {
      state.defaultLocation = data;
    },

    setMarketList: (state, data) => {
      state.marketList = data;
    },
  },
};
