import moduleConfig from "./wishlist.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./wishlist.module"),
    children: [
      {
        path: "/",
        name: "wishlist",
        component: () => import("./views/wishlist"),
      },
    ],
  },
];
