import { facetSearch, filterFacet } from "./services/search";

export default {
  namespaced: true,
  state: {
    productslist: [],
    filters: {},
    compare: [],
    brandArr: [],
    currentPosition: "",
    keywordfacet: {},
    searchText: "",
    maxPriceFilter: { min: 750, max: 1500 },
    filter: {
      Brand: {
        type: "multi-checkbox",
      },
      Price: {
        type: "range",
      },
    },
  },
  getters: {
    getKeywordFilters: (state) => {
      return state.keywordfacet;
    },
    getCurrentPosition: (state) => {
      return state.currentPosition;
    },
    getFilterType: (state) => {
      return state.filter;
    },
    getBrand: (state) => {
      return state.brandArr;
    },
    getmaxPriceFilter: (state) => {
      return state.maxPriceFilter;
    },
    getProductsList: (state) => {
      return state.productslist;
    },
    compareItems: (state) => {
      return state.compare;
    },
    getProductById: (state) => {
      return (id) =>
        state.productslist.find((product) => {
          return product.main._id === +id;
        });
    },
  },
  actions: {
    getFacetSearch: async (context, payload) => {
      const result = await facetSearch(payload);
      return result;
    },
    getFilterFacet: async (context, payload) => {
      let result = await filterFacet(payload);
      return result;
    },

    async changeKeywordFilter({ commit }, payload) {
      try {
        await commit("changeKeywordFilters", payload);
      } catch (error) {
        console.log("Got Error :- ", error);
      }
    },
    async setSearchText({ commit }, payload) {
      try {
        await commit("setSearchTerm", payload);
      } catch (error) {
        console.log("Got Error :- ", error);
      }
    },
    async setFilter({ commit }, payload) {
      try {
        await commit("setFilters", payload);
      } catch (error) {
        console.log("Got Error :- ", error);
      }
    },
    actionProductList: (context, payload) => {
      context.commit("setProductList", payload);
    },
    setKeywordFacet: (context, payload) => {
      context.commit("keywordfilters", payload);
    },
    addToCompare: (context, payload) => {
      context.commit("addToCompare", payload);
    },
    setBrandArr: (context, payload) => {
      context.commit("brandArr", payload);
    },
    actionMaxPrice: (context, payload) => {
      context.commit("actionMaxPrice", payload);
    },
    removeCompareItem: (context, payload) => {
      context.commit("removeCompareItem", payload);
    },
    actionCurrentPosition: (context, payload) => {
      context.commit("setCurrentPosition", payload);
    },
    sortProducts: (context, payload) => {
      context.commit("sortProducts", payload);
    },
  },
  mutations: {
    changeKeywordFilters: (state, payload) => {
      const { key, value } = payload;
      state.filters.keywordFilters = state.filters.keywordFilters.map(
        (filter) => {
          // if keywords exist - present in the array
          if (
            filter.key === key &&
            filter.selectedBuckets &&
            filter.selectedBuckets.indexOf(value) !== -1
          ) {
            filter.selectedBuckets = filter.selectedBuckets.filter(
              (obj) => obj !== value
            );
            return {
              ...filter,
            };
          }
          // if keyword exist not present in the array
          else if (
            filter.key === key &&
            filter.selectedBuckets &&
            filter.selectedBuckets.indexOf(value) === -1
          ) {
            let selectedBucket = [];
            selectedBucket = filter.selectedBuckets;
            selectedBucket.push(value);
            return {
              ...filter,
              selectedBuckets: selectedBucket,
            };
          }
          // if first time key add to facet
          else if (filter.key === key && !filter.selectedBuckets) {
            let selectedBucket = [];
            selectedBucket.push(value);
            return {
              ...filter,
              selectedBuckets: selectedBucket,
            };
          }
          // else return object
          else {
            return filter;
          }
        }
      );
    },
    setFilters: (state, payload) => {
      let keywordFilter =
        payload.aggregations.agg_keyword_facet.attribute_name.buckets;
      let rangeFilters =
        payload.aggregations.agg_range_facet.attribute_name.buckets;
      let object = {
        keywordFilters: keywordFilter,
        rangeFilters: rangeFilters,
      };

      state.filters = object;
    },
    setSearchTerm: (state, payload) => {
      state.searchText = payload;
    },
    keywordfilters: (state, payload) => {
      state.keywordfacet = payload;
    },
    setProductList: (state, result) => {
      let finalArr = [];

      result.filter((ele) => {
        var tempProductInfo = {};
        var productInfo = {};
        tempProductInfo._id = ele._id;
        for (const i in ele._source) {
          if (typeof ele._source[i] == "string") {
            tempProductInfo[i] = ele._source[i];
          }
        }
        if (ele._source.string_attributes) {
          for (const i of ele._source.string_attributes) {
            tempProductInfo[i.attribute_name] = i.attribute_value;
          }
        }
        if (ele._source.integer_attributes) {
          for (const i of ele._source.integer_attributes) {
            tempProductInfo[i.attribute_name] = i.attribute_value;
          }
        }
        if (ele._source.product_summary.images) {
          tempProductInfo.images = ele._source.product_summary.images;
        }

        productInfo = { ...tempProductInfo, ...ele._source };
        if (productInfo["Availability"]) {
          productInfo.availability = productInfo["Availability"];
        }
        productInfo["productType"] = ele["_source"]["productType"];

        finalArr.push(productInfo);
      });

      state.productslist = finalArr.filter(
        (obj) => obj.productType !== "CHILD"
      );
    },
    brandArr: (state, payload) => {
      state.brandArr = payload;
    },
    actionMaxPrice: (state, payload) => {
      state.maxPriceFilter = payload;
    },
    removeCompareItem: (state, payload) => {
      const index = state.compare.indexOf(payload);
      state.compare.splice(index, 1);
    },
    setCurrentPosition: (state, payload) => {
      state.currentPosition = payload;
    },
    addToCompare: (state, payload) => {
      const compareItems = state.compare.find(
        (item) => item.main._id == payload.main._id
      );
      if (!compareItems) {
        state.compare.push({
          ...payload,
        });
      }
    },
    sortProducts: (state, payload) => {
      if (payload === "low") {
        state.productslist.sort(function (a, b) {
          return +a.Price - +b.Price;
        });
      } else if (payload === "high") {
        state.productslist.sort(function (a, b) {
          return +b.Price - +a.Price;
        });
      }
    },
  },
};
