import moduleConfig from "./sections.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./sections.module"),
    children: [
      {
        path: "/",
        name: "Section",
        meta: {
          layout: () => import("@/layouts/default"),
        },
        component: () => import("./views/Section.vue"),
      },
    ],
  },
];
