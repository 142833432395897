export default {
  key: "advertisement",
  basePath: '/advertisement',
  exclude: false,
  components: [
    {
      name: 'banner',
      component: () => import('./components/banner')
    },
    {
      name: 'bottom-banner',
      component: () => import('./components/bottom_banner')
    },
    {
      name: 'middle-offer',
      component: () => import('./components/middle_offer')
    },
    {
      name: 'offer',
      component: () => import('./components/offer')
    },
    {
      name: 'top-banner',
      component: () => import('./components/top_banner')
    },
    {
      name: 'top-banner-text',
      component: () => import('./components/top_banner_text')
    },{
      name: 'loading',
      component: () => import('./components/loading')
    },
  ]
}
  