import {listWishList, removeToWishList, addToWishlist,clearWishlist } from "./services";
import productDataFilter from "@/shared/productDataFilter";

export default {
  namespaced: true,
  state: {
    listWishListData : [],
    removeToWishListData: [],
    isLoading : false,    
    listData: [],
  },

  actions: {
    async clearWishlist(context) {
      try {
        let clr = await clearWishlist();
        context.commit("setWishlistData", []);
        context.dispatch("addToWishlist");
        return clr;
      } catch (err) {
        return err;
      }
    },

    async removeToWishList(context, product) {
      const payload = { item: product._id };
     await removeToWishList(payload);
      context.commit("setremoveToWishList", product);
    }, 

    async listWishList(context) {
      this.isLoading = true;
      let res = await listWishList();
      let result = productDataFilter.methods.filterProductData(res.response.items);
      this.isLoading = false;
      context.commit("setWishlistData", result);
      
    },
    
    async addToWishlist(context, product) {
      const payload = { item: product._id };
       await addToWishlist(payload);
      context.commit("addToWishlistMutation",product);
    },  
  },

  mutations: {
    setremoveToWishList(state, data) {
      state.listData=state.listData.filter(el=>el._id!=data._id)
    },
    setWishlistData(state, data) {
      state.listData = data;
    },
    addToWishlistMutation(state, data){
      let listData = state.listData
      listData.push(data)
      state.listData = listData
    }
  },
};