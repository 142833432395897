import moduleConfig from "./healthcare_market.config";
export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./healthcare_market.module"),
    children: [
      {
        path: ":market",
        name: "healthcare-market-view",
        component: () => import("./views/healthcare_market.vue"),
      },
      
    ],
  },
];
