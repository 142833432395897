import vue from "vue";

export const removeToWishList = async (payload) =>
await vue.axios.post("/cart/favorite/remove", payload);

export const listWishList = async () => {
    const res = await vue.axios.get(`/cart/favorite/view`);
    return res.data;
};

export const addToWishlist = async (payload) =>
await vue.axios.post("/cart/favorite/add", payload);

export const clearWishlist = async () => await vue.axios.delete("/cart/favorite/remove-all");

// export const addToWishlist = async (productId) => {
//     const payload = { item: productId };
//     const res = await vue.axios.post(`/cart/favorite/add`, payload);
//     return res.data;
// };
