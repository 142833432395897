import moduleConfig from "./shopDetails.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./shopDetails.module"),
    children: [
      {
        path: "/shop-details/:id/:items",
        name: "shopDetails",
        component: () => import("./views/shopDetails"),
      },
    ],
  },
];
