import moduleConfig from "./market.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./market.module"),
    children: [
      {
        path: ":market",
        name: "market-view",
        component: () => import("./views/market.vue"),
      },
      {
        path: "/market/:market/:items",
        name: "market-listing",
        component: () => import("./views/marketListing.vue"),
      },
    ],
  },
];
