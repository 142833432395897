export default {
  key: "sections",
  basePath: "/sections",
  exclude: false,
  components: [
    {
      name: "slider",
      component: () => import("./components/slider"),
    },
  ],
};
