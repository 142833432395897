import {
  getProductVariantDetails,
  fetchProductDetails,
  addProductReview,
  listProductFeedback,
  fetchRelatedIems,
  fetchNewStores,
  fetchAllItems,
} from "./services";
import { structureItemData } from "/src/helpers/items";

export default {
  namespaced: true,
  state: {
    categoryList: {},
    productDetailsList: {},
    productVariantData: [],
    relatedItemList: {},
    productFeedbackList: [],
    newStores: [],
    defaultLocation: {
      City: "",
      Country: "",
      State: "",
      zip: "",
    },
    itemSuggestions: [],
    trendingItems: [],
    isLoading: false,
  },
  getters: {
    getProductData(state) {
      return (id) => state.productDetailsList[id];
    },
    getRelatedItemsData(state) {
      return (id) => state.relatedItemList[id];
    },
    getProductFeedbackData(state) {
      return (id) => state.productFeedbackList[id];
    },
  },
  actions: {
    fetchNewStores: async ({ commit, state }, payload) => {
      try {
        if (
          !state.newStores ||
          Object.keys(state.newStores).length === 0 ||
          payload.refresh
        ) {
          delete payload.refresh;
          commit("setNewStores", []);
          const data = await fetchNewStores(payload);
          commit("setNewStores", data.response);
        }
      } catch (e) {
        //
      }
    },

    fetchProductDetails: async ({ commit, state }, item, refresh = false) => {
      try {
        if (!state.productDetailsList[item] || refresh) {
          const data = await fetchProductDetails(item);
          commit("setProductDataList", {
            key: item,
            value: structureItemData([data])[0],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    fetchProductVariantDetails: async (context, payload) => {
      let res = await getProductVariantDetails(payload);
      context.commit("setProductVariantData", res);
      return res;
    },

    updateProductReview: async (_, payload) => {
      let res = await addProductReview(payload);
      return res;
    },

    fetchProductFeedback: async ({ commit, state }, id, refresh = false) => {
      try {
        if (!state.productFeedbackList[id] || refresh) {
          let result = await listProductFeedback(id);
          console.log(result);

          commit("setProductFeedback", {
            key: id,
            value: result[0],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    fetchRelatedItemData: async (
      { commit, state },
      catalogId,
      refresh = false
    ) => {
      try {
        if (!state.relatedItemList[catalogId] || refresh) {
          const result = await fetchRelatedIems({
            city: "",
            location: {
              lat: 17.385044,
              lon: 78.486671,
            },
            distance: 10,
            catalogs: [catalogId],
          });
          commit("setRelatedItemData", {
            key: catalogId,
            value: structureItemData(result[catalogId]),
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    fetchAllItems: async ({ commit, state }, refresh = false) => {
      try {
        if (
          (!state.itemSuggestions.length && !state.trendingItems.length) ||
          refresh
        ) {
          commit("setState", {
            itemSuggestions: [],
            trendingItems: [],
            //isLoading : true
          });
          const result = await fetchAllItems({
            //city: [state.defaultLocation.City],
            page: { page: 1, itemsPerPage: 20 },
          });

          commit("setState", {
            itemSuggestions: structureItemData(result.data.itemSuggestions),
            trendingItems: structureItemData(result.data.trendingItems),
            //isLoading : false
          });
        }
      } catch (e) {
        //
      }
    },

    setLocation: ({ commit, dispatch }, data) => {
      commit("setLocationData", data);
      dispatch("fetchAllItems", true);
      dispatch("fetchNewStores", true);
    },
  },

  mutations: {
    setProductDataList: (state, data) => {
      console.log(data);
      state.productDetailsList = {
        ...state.productDetailsList,
        [data.key]: data.value,
      };
    },

    setProductVariantData: (state, payload) => {
      state.productVariantData = payload;
    },

    setRelatedItemData: (state, data) => {
      console.log(data);
      state.relatedItemList = {
        ...state.relatedItemList,
        [data.key]: data.value,
      };
    },

    setProductFeedback: (state, data) => {
      state.productFeedbackList = {
        ...state.productFeedbackList,
        [data.key]: data.value,
      };
    },

    setNewStores(state, data) {
      state.newStores = data;
    },

    setState: (state, data) => {
      Object.keys(data).map((field) => {
        state[field] = data[field];
      });
    },

    setLocationData: (state, data) => {
      state.defaultLocation = data;
    },
  },
};
