export default {
  key: "checkout",
  basePath: "/checkout",
  exclude: false,
  components: [
    {
      name: "order-summary",
      component: () => import("./components/order-summary"),
    },
    {
      name: "price-card",
      component: () => import("./components/price-card"),
    },
    {
      name: "increment-decrement",
      component: () => import("./components/increment-decrement"),
    },
    {
      name: "cart-coupon",
      component: () => import("./components/cart-coupon"),
    },
    {
      name: "cart-payment",
      component: () => import("./components/cart-payment"),
    },
    {
      name: "transaction-benefits",
      component: () => import("./components/transaction-benefits"),
    },
  ],
};
