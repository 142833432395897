//
import vue from "vue";

export const loginApi = async (payload) =>
  await vue.axios.post("/cap/users/customer/login-otp", payload);

export const socialLoginSignup = async (payload) =>
  await vue.axios.post(
    `/cap/users/customer/social-login?providerType=${payload.type}`,
    payload.data
  );

export const createUser = async (payload) =>
  await vue.axios.post(`/cap/users/customer`, payload);

export const forgotPassword = async (payload) =>
  await vue.axios.post(`/cap/users/customer/forgot-password`, payload);  

  export const resetPassword = async (payload) =>
  await vue.axios.post(`/cap/users/customer/reset-password`, payload);  

export const loginWithPwd = async (payload) =>
  await vue.axios.post("cap/users/customer/login", payload);

export const getTenantConfig = async () =>
  await vue.axios.post("/cap/users/tenant/get-config", {});
