import {EventEmitter} from 'events';

const locationEvent = new EventEmitter();

export default {
  channels: {
    locationChanged: 'locationChanged'
  },
  listener: locationEvent
}
