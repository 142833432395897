import {fetchHealthCareList} from "./services/index.js";
  export default {
    namespaced: true,
    state: {
      itemsList:[],
    },
  
    actions: {
    fetchHealthCareList : async ({ commit, state },market, refresh = false) => {
      try {
        if ((!state.itemsList) || refresh) {
          commit("setHealthCareList", []);
        }
        const data = await fetchHealthCareList(market);
        const result = data
        commit("setHealthCareList", result)
      }
      catch (e) {
        //  
      }
    },
    },
    mutations: {
      setHealthCareList: (state, data) => {
        state.itemsList = data;
      },
      
    }
  }
  
  
