import vue from "vue";

export const fetchMarketList = async (city) => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/catalog/filter?isMarket=y&city=${city}`
  );
  return res.data.response;
};

export const fetchNewStores = async (payload) => {
  const res = await vue.axios.post(`/cap/users/partner/nearby`, payload);
  return res.data;
};

export const fetchAllItems = async (payload) => {
  const res = await vue.axios.post(`/home/list/all`, payload);
  return res.data;
};

export const fetchNewArrival = async (payload) => {
  const res = await vue.axios.post("/home/list/new-arrivals", payload);
  return res.data;
};

export const getBillPaymentCategory = async (payload) => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/catalog/tree-public?market=${payload}`
  );
  return res.data.response;
}
