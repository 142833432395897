import vue from "vue";

const searchTextSuggest = async (payload) => {
  const res = await vue.axios.post("/search/autosuggest", payload);
  return res;
};

const fullTextSearch = async (payload) => {
  const res = await vue.axios.post("/search/fulltext/search", payload);
  return res.data;
};

const facetSearch = async (payload) => {
  const res = await vue.axios.post("/search/fulltext/facet", payload);
  return res.data;
};

const filterFacet = async (payload) => {
  const res = await vue.axios.post("/search/fulltext/facet", payload);
  return res.data;
};

export { searchTextSuggest, fullTextSearch, facetSearch, filterFacet };
