import {
  fetchSpecificItemsList, fetchAllItems, pharmacyStoreList,
  listProductFeedback,
  createQuote,
  listQutableItem, uploadImageToStorage,
} from "./services/index.js";
import { structureItemData } from "/src/helpers/items";
export default {
  namespaced: true,
  state: {
    specificItemsList: {},
    itemSuggestions: [],
    newArrivals: [],
    pharmacyStore: [],
    defaultLocation: {
      City: "Hyderabad",
      Country: "India",
      State: "Telangana",
      zip: "500004",
    },
    productFeedbackList: [],
  },

  getters: {
    getProductFeedbackData(state) {
      return (id) => state.productFeedbackList[id] || {};
    },
  },

  actions: {
    fetchProductFeedback: async (
      { commit, state },
      payload,
      refresh = false
    ) => {
      try {
        if (!state.productFeedbackList[payload._id] || refresh) {
          let result = await listProductFeedback(payload.catalogId);
          commit("setProductFeedback", {
            key: payload._id,
            value: result?.[0],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    uploadImageToStorage: async (_, payload) => {
      return uploadImageToStorage(payload);
    },

    createQuote: async (_, payload) => {
      return createQuote(payload);
    },

    listQutableItem: async (_, payload) => {
      return listQutableItem(payload);
    },

    fetchPharmacyStore: async ({ commit }, payload) => {
      commit("setPharmacyStore", []);
      const data = await pharmacyStoreList(payload);
      commit("setPharmacyStore", data.data.response);
    },

  fetchSpecificItemsList: async ({ commit, state }, id, refresh = false) => {
    try {
      if ((!state.specificItemsList) || refresh) {
        commit("setSpecificItemsList", []);
      }
      const data = await fetchSpecificItemsList(id);
      const result = data
      commit("setSpecificItemsList", result)
    }
    catch (e) {
      //  
    }
  },

  fetchAllItems: async ({ commit, state }, market, refresh = false) => {
    try {
      if (
        (!state.itemSuggestions.length && !state.newArrivals.length) ||
        refresh
      ) {
        commit("setState", {
          itemSuggestions: [],
          newArrivals: [],
          isLoading: true
        });
        const result = await fetchAllItems({
          city: [state.defaultLocation.City],
          market: [market],
          page: { page: 1, itemsPerPage: 20 },
        });
        commit("setState", {
          itemSuggestions: structureItemData(result.data.itemSuggestions),
          newArrivals: structureItemData(result.data.newArrivals),
          isLoading: false
        });
      }
    } catch (e) {
      //
    }
   },
  },
  mutations: {
    setPharmacyStore(state, data) {
      state.pharmacyStore = data;
    },
    setProductFeedback: (state, data) => {
      state.productFeedbackList = {
        ...state.productFeedbackList,
        [data.key]: data.value,
      }
    },
    setSpecificItemsList: (state, data) => {
      state.specificItemsList = data;
    },
    setState: (state, data) => {
      Object.keys(data).map((field) => {
        state[field] = data[field];
      });
    },
  }
}

