export default {
  key: "healthCare",
  basePath: "/health-care",
  exclude: false,
  components: [
    {
      name: "category",
      component: () => import("./components/category"),
    },
  ],
};
