import productDataFilter from "@/shared/productDataFilter";
import {
  fetchStoreCategoryList,
  fetchItemsList,
  getPartnerInfo,
} from "./services";

export default {
  namespaced: true,
  state: {
    categoryList: {},
    itemsList: [],
    allCategoryList: [],
    partnerInfo: [],
  },

  actions: {
    fetchStoreCategoryList: async ({ commit }, id) => {
      try {
        const data = await fetchStoreCategoryList(id);
        let unique = data.filter(
          (a, i) => data.findIndex((s) => a.parent._id === s.parent._id) === i
        );
        commit("setCategoryList", unique);
        commit("setallCategoryList", data);
      } catch (e) {
        //
      }
    },

    fetchPartnerInfo: async (context, payload) => {
      let res = await getPartnerInfo(payload);
      context.commit("setPartnerInfo", res);
    },

    sortProducts: (context, payload) => {
      context.commit("sortProducts", payload);
    },

    fetchItemsList: async ({ commit, state }, payload, refresh = false) => {
      try {
        if (!state.itemsList || refresh) {
          commit("setItemsList", []);
        }
        const data = await fetchItemsList(payload);
        const response = data.data.hits;
        const result = response.hits.map((each) => {
          return {
            ...each._source,
            _id: each._id,
          };
        });
        let resultFinal = await productDataFilter.methods.filterProductData(
          result
        );
        commit("setItemsList", resultFinal);
      } catch (e) {
        //
      }
    },
  },
  mutations: {
    setallCategoryList: (state, data) => {
      state.allCategoryList = data;
    },
    setCategoryList: (state, data) => {
      const result = data;
      state.categoryList = result;
    },
    setItemsList: (state, data) => {
      state.itemsList = data;
    },
    setPartnerInfo: (state, data) => {
      state.partnerInfo = data;
    },
    sortProducts: (state, payload) => {
      if (payload === "a-z") {
        state.itemsList.sort(function (a, b) {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          } else if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      } else if (payload === "z-a") {
        state.itemsList.sort(function (a, b) {
          if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return -1;
          } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      } else if (payload === "low") {
        state.itemsList.sort(function (a, b) {
          return +a.Price - +b.Price;
        });
      } else if (payload === "high") {
        state.itemsList.sort(function (a, b) {
          return +b.Price - +a.Price;
        });
      }
    },
  },
};
