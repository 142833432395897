import productDataFilter from "@/shared/productDataFilter";


const structureItemData = (data, filterChild = true) => {
  let result = productDataFilter.methods.filterProductData(data);
  if (filterChild) {
    result = result.filter((obj) => obj.productType !== "CHILD")
  }
  return result
}

export {
  structureItemData
}
