import moduleConfig from "./auth.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./auth.module"),
    children: [
      {
        path: "/login",
        meta: { laylout: "test-laylout" },
        name: "login",
        component: () => import("./views/login"),
      },
      {
        path: "/register",
        meta: { laylout: "test-laylout" },
        name: "register",
        component: () => import("./views/register"),
      },
      {
        path: "/forgot-password",
        meta: { laylout: "test-laylout" },
        name: "forgotPassword",
        component: () => import("./views/forgotPassword"),
      },
      {
        path: ":resetRefId",
        meta: { laylout: "test-laylout" },
        name: "newPassword",
        component: () => import("./views/newPassword"),
      },
    ],
  },
];
