<template>
  <div id="app">
    <component :is="this.$router.currentRoute.meta.laylout || 'default-layout'">
      <router-view />
    </component>
    
  </div>
</template>

<script>

import defaultLayout from "@/layouts/default";
export default {
  name : "App",
  components: {
    defaultLayout,
  },
};
</script>
