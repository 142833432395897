import moduleConfig from "./checkout.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./checkout.module"),
    children: [
      {
        path: "/",
        name: "checkout",
        component: () => import("./views/checkout"),
      },
      {
        path: "/order-success",
        name: "order-success",
        component: () => import("./views/order-success"),
      },
    ],
  },
];
