import moduleConfig from "./category.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./category.module"),
    children: [
      {
        path: "/shopDetails/:providerId/:category/:parentId",
        name: "category-view",
        component: () => import("./views/category.vue"),
      },
    ],
  },
];
