import { banner } from "./services/index.js";

export default {
  namespaced: true,
  state: {
    topBanner: {},
    offer1: {},
    offer2: {},
    searchBanner: {},
    isLoading: true,
  },
  getters: {},
  actions: {
    topBannerData: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.topBanner.url || refresh) {
          commit("setTopBanner", {});
          const data = await banner({
            placement: "TOP_HOME_BANNER_WEB",
          });
          commit("setTopBanner", data.response.data);
        }
      } catch (e) {
        //
      }
    },
    offerData1: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.offer1.url || refresh) {
          commit("setOffer1", {});
          const data = await banner({
            placement: "EPARISHEVA_HOME_MIDDLE1",
          });
          commit("setOffer1", data.response);
        }
      } catch (e) {
        //
      }
    },
    offerData2: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.offer1.url || refresh) {
          commit("setOffer2", {});
          const data = await banner({
            placement: "EPARISHEVA_HOME_MIDDLE2",
          });
          commit("setOffer2", data.response);
        }
      } catch (e) {
        //
      }
    },
    searchBannerData: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.searchBanner.url || refresh) {
          commit("setSearchBanner", {});
          const data = await banner({
            placement: "SEARCH_TOP_WEB",
          });
          commit("setSearchBanner", data.response.data[0]);
        }
      } catch (e) {
        //
      }
    },
  },
  mutations: {
    setTopBanner(state, data) {
      state.topBanner = data;
    },
    setOffer1(state, data) {
      state.offer1 = data;
    },
    setOffer2(state, data) {
      state.offer2 = data;
    },
    setSearchBanner(state, data) {
      state.searchBanner = data;
    },
  },
};
