export default {
    namespaced: true,
    state: {
        websiteMode: "product"
    },
    actions: {
        setWebsiteMode(store,payload) {
            store.commit("updateWebsiteMode", payload)
        }
    },
    mutations: {
        updateWebsiteMode(state,payload) {
            state.websiteMode = payload
        }
    }
}