export default {
    key: "search",
    basePath: '/search',
    exclude: false,
    components: [
      {
        name: 'bar',
        component: () => import('./components/search')
      },
      {
        name: 'side-bar',
        component: () => import('./components/filterSideBar')
      },
    ]
  }
  