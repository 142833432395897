import moduleConfig from "./product.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./product.module"),
    children: [
      {
        path: "/listing/:items",
        name: "product-listing",
        component: () => import("./views/productListing.vue"),
      },
      {
        path: "/new-arrivals-listing",
        name: "new-arrivals-listing",
        component: () => import("./views/new-arrivals-listing.vue"),
      },
      {
        path: ":data",
        name: "product-view",
        component: () => import("./views/productPage"),
      },
    ],
  },
];
