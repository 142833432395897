import moduleConfig from "./return_refund_policy.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./return_refund_policy.module"),
    children: [
      {
        path: "/",
        name: "refund_return_policy",
        component: () => import("./views/return_refund_policy"),
      },
    ],
  },
];
