import moduleConfig from "./address.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./address.module"),
    children: [],
  },
  
];
