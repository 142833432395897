import {
  fetchMarketList,
  fetchNewStores,
  fetchAllItems,
  fetchNewArrival,
  getBillPaymentCategory,
} from "./services";
import { structureItemData } from "/src/helpers/items";

export default {
  namespaced: true,
  state: {
    marketList: null,
    newStores: [],
    defaultLocation: {
      City: "",
      Country: "",
      State: "",
      zip: "",
    },
    itemSuggestions: [],
    trendingItems: [],
    newArrivals: [],
    billCategoryList: [],
    marketListData: {},
    isLoading: false,
  },

  actions: {
    fetchBillPaymentCategoryData: async ({ commit }) => {
      try {
        const payload = "62d4fef39c487b63efee8f03";
        const data = await getBillPaymentCategory(payload);
        commit("setBillCategoryData", data);
      } catch (e) {
        console.log(e);
      }
    },
    fetchMarketList: async ({ commit, state }) => {
      try {
        let City = [state.defaultLocation.City];
        if (City === "") {
          City = sessionStorage.getItem("eparisheva:location").data.City;
        }
        const data = await fetchMarketList("");
        const result = data.filter((x) => x.isMarket);
        commit("setMarketList", result);
      } catch (e) {
        //
      }
    },

    fetchNewStores: async ({ commit, state }, payload) => {
      try {
        if (
          !state.newStores ||
          Object.keys(state.newStores).length === 0 ||
          payload.refresh
        ) {
          delete payload.refresh;
          commit("setNewStores", []);
          const data = await fetchNewStores(payload);
          commit("setNewStores", data.response);
        }
      } catch (e) {
        //
      }
    },
    fetchAllItems: async (context, payload) => {
      try {
        context.commit("setState", {
          itemSuggestions: [],
          newArrivals: [],
          trendingItems: [],
          isLoading: true,
        });
        let payloadData = {
          page: { page: 1, itemsPerPage: 20 },
        };
        if (payload.city !== 6000 && payload.city != undefined) {
          console.log("condition chcked");
          payloadData.city = [payload.city];
        }
        const result = await fetchAllItems(payloadData);

        context.commit("setState", {
          itemSuggestions: structureItemData(result.data.itemSuggestions),
          newArrivals: structureItemData(result.data.newArrivals),
          trendingItems: structureItemData(result.data.trendingItems),
          isLoading: false,
        });
      } catch (e) {
        //
      }
    },

    fetchMarketItemsData: async ({ commit, state }, location) => {
      try {
        const marketListData = {};
        const marketData = state.marketList.map(async (market) => {
          const dataPayload = {
            market: [market.name],
            page: { page: 1, itemsPerPage: 20 },
          };
          if (location === "") dataPayload["city"] = [""];

          const result = await fetchNewArrival(dataPayload);
          marketListData[market.name] = structureItemData(result.data);
          return;
        });
        await Promise.all(marketData);
        commit("setMarketListData", marketListData);
        const result2 = await fetchNewArrival(this.dataPayload);
        commit("setnewArrivals", structureItemData(result2.data.newArrivals));
      } catch (e) {
        //
      }
    },

    setLocation: ({ commit, dispatch }, data) => {
      commit("setLocationData", data);
      dispatch("fetchAllItems", true);
      dispatch("fetchMarketItemsData");
      dispatch("fetchMarketList", true);
    },
  },
  mutations: {
    setBillCategoryData: (state, data) => {
      state.billCategoryList = data.filter((e) => e.isCategory == true);
    },
    setMarketList: (state, data) => {
      state.marketList = data;
    },
    setnewArrivals(state, data) {
      state.newArrivals = data;
    },
    setNewStores(state, data) {
      state.newStores = data;
      this.isLoading = false;
    },

    setState: (state, data) => {
      Object.keys(data).map((field) => {
        state[field] = data[field];
      });
    },

    setLocationData: (state, data) => {
      state.defaultLocation = data;
    },

    setMarketListData: (state, data) => {
      state.marketListData = data;
    },

    setStore: (state, data) => {
      state.stores = data;
    },
  },
};
