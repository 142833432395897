<template>
  <div>
    <!----------Header Section ------------>
    <div class="primary-header desktop-view-header">
      <b-container fluid id="element">
        <b-row>
          <b-col md="1">
            <div class="logo text-center">
              <a
                style="cursor: pointer"
                to="/"
                v-if="modules.home"
                @click="() => updateWebsiteMode('product')"
              >
                <img width="65px" class="pt-2" src="img/navbar/logo.png" />
              </a>
            </div>
          </b-col>
          <b-col md="8">
            <ul class="top-header-leftside">
              <li>
                <template v-if="$options.components['location-address']">
                  <location-address />
                </template>
              </li>
              <li>
                <div>
                  <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="btn-radios-2"
                      v-model="selected"
                      :options="options"
                      @input="updateWebsiteMode"
                      :aria-describedby="ariaDescribedby"
                      button-variant="outline-primary"
                      name="radio-btn-outline"
                      buttons
                      class="header-PS"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
              </li>
              <li>
                <template v-if="$options.components['search-bar']">
                  <search-bar />
                </template>
              </li>
              <li>
                <div class="select-style">
                  <b-form-select
                    v-model="selectedDistance"
                    :options="distanceOptions"
                    @input="fetchByDistance"
                  ></b-form-select>
                </div>
              </li>
            </ul>
          </b-col>
          <b-col md="3">
            <ul class="top-header-rightside">
              <li>
                <div class="available-onsec">
                  Available On
                  <div class="android-iphone-icon">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.eparisheva.client&hl=en_IN&gl=US"
                      target="_blank"
                    >
                      <img src="img/navbar/top_Play.png" alt width="30px" />
                    </a>
                    <!-- <a
                      href="https://apps.apple.com/in/app/teka-customer/id1635748798"
                      target="_blank"
                    >
                      <img
                        src="img/navbar/Top_App.png"
                        alt
                        width="30px"
                        class="ml-1"
                      />
                    </a> -->
                  </div>
                </div>
              </li>
              <li>
                <b-button
                  v-if="!$store.state['auth'].isLoggedin"
                  @click="() => this.$router.push({ name: 'login' })"
                  variant="outline-light"
                  class="ml-2 mr-2"
                  >Login</b-button
                >
                <div
                  v-if="$store.state['auth'].isLoggedin"
                  class="header-loginsec"
                >
                  <b-dropdown id="dropdown-1" class>
                    <template #button-content>
                      <b-icon icon="person-circle"></b-icon>
                      {{ userInfo.name.first.substring(0, 4) + "..." }}
                    </template>
                    <b-dropdown-item @click="$router.push('/profile')">
                      <img class src="img/navbar/profile.png" /> Profile
                    </b-dropdown-item>
                    <b-dropdown-item @click="$router.push('/wishlist')">
                      <img class src="img/navbar/wishlist.png" /> WishList
                    </b-dropdown-item>
                    <b-dropdown-item @click="myOrder">
                      <img class src="img/navbar/order.png" /> My Order
                    </b-dropdown-item>
                    <b-dropdown-item @click="logout">
                      <img class src="img/navbar/logout.png" /> Log out
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </li>
              <li>
                <!-- <div class="wishlist-header">
                  <router-link :to="{ path: '/wishlist' }">
                  <b-iconstack class="ml-4 mt-2">
                      <b-icon scale="3" stacked icon="circle-fill" variant="light"></b-icon>
                      <b-icon icon="heart" scale="2" variant="orange"></b-icon>
                    </b-iconstack>
                   <b-icon icon="heart"></b-icon> 
                     <span
                      v-if="wishlist.length > 0"
                      class="wishlist-notification"
                      >{{ wishlist.length }}
                    </span> 
                   </router-link>
                  </div>-->
                <div class="header-cart">
                  <router-link :to="{ name: 'checkout' }">
                    <b-iconstack class="ml-4">
                      <b-icon
                        scale="3"
                        stacked
                        icon="circle-fill"
                        variant="light"
                      ></b-icon>
                      <b-icon icon="cart3" scale="2" variant="orange"></b-icon>
                    </b-iconstack>
                  </router-link>
                  <h6 v-if="cartData.length > 0" class="checkout-notification">
                    {{
                      Array.isArray(cartData) && cartData.length > 0
                        ? cartData.length
                        : ""
                    }}
                  </h6>
                </div>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="primary-header mobile-view-header">
      <b-container fluid id="element">
        <b-col sm="12">
          <div class="d-flex justify-content-between mt-2">
            <div class="location-mobile-view">
              <template v-if="$options.components['location-address']">
                <location-address modalId="popover2" />
              </template>
            </div>
            <div class="mb-0">
              <b-navbar-nav class="top-header-rightside">
                <b-nav-form>
                  <!-- <b-icon class="text-white" scale="1.5" icon="chat-left-dots"></b-icon> -->
                  <div class="header-cart">
                    <router-link :to="{ name: 'checkout' }">
                      <b-iconstack class="ml-4">
                        <b-icon
                          scale="2.5"
                          stacked
                          icon="circle-fill"
                          variant="light"
                        ></b-icon>
                        <b-icon
                          icon="cart3"
                          scale="1.5"
                          variant="orange"
                        ></b-icon>
                      </b-iconstack>
                    </router-link>
                    <h6
                      v-if="cartData.length > 0"
                      class="checkout-notification"
                    >
                      {{
                        Array.isArray(cartData) && cartData.length > 0
                          ? cartData.length
                          : ""
                      }}
                    </h6>
                  </div>
                </b-nav-form>
              </b-navbar-nav>
            </div>
          </div>
        </b-col>
        <b-col sm="12">
          <div class="search-mobile-view">
            <template v-if="$options.components['search-bar']">
              <search-bar />
            </template>
          </div>
        </b-col>
      </b-container>
    </div>

    <b-container fluid class="p-0 margin-top-98 mobile-hide-margin">
      <slot></slot>
    </b-container>

    <!-----------default section---------->
    <div id="helpContent">
      <div class="tap-top top-cls" v-scroll-to="'#element'">
        <div>
          <b-icon icon="chevron-double-up" aria-hidden="true"></b-icon>
        </div>
      </div>
      <div v-if="this.$route.path !== `/`">
        <div @click="clickFunc()" class="tap-top2 top-cls2">
          <div class="margin-top-2">
            <b-icon icon="telephone-fill" aria-hidden="true"></b-icon>
          </div>
        </div>
        <div v-if="clickHelp" class="tap-top3 top-cls3">
          <div>
            <div class="mt-n1">Helpline</div>
            <span>
              <a href="tel:+919550265720" class="text-white">{{
                partnerInfo.preferences.delivery.deliveryOwner.phone
              }}</a>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-------------banner---------------->
    <!-- <b-container else>
        <b-row>
          <b-col md="12" class="mt-3">
            <template v-if="$options.components['advertisement-bottom-banner']">
              <advertisement-bottom-banner />
            </template>
          </b-col>
        </b-row>
      </b-container>-->

    <!------------footer section ---------->
    <div>
      <b-container
        class="bv-example-row w-auto bg-dark text-white pl-5 pr-5 mt-5"
        fluid
      >
        <b-row>
          <b-col md="4">
            <div class="mt-5">
              <h5 class="my-4">Get To Know Us</h5>
              <div class="knowsus">
                <router-link v-if="modules.home" to="/">Home</router-link>
                <router-link v-if="modules.about" to="/about"
                  >About Us</router-link
                >
                <!-- <router-link v-if="modules.returnRefundPolicy" to=""
                  >Your Account</router-link
                > -->
                <router-link
                  v-if="modules.returnRefundPolicy"
                  to="/return-refund-policy"
                  >Return and Refund Policy</router-link
                >
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <div class="mt-5">
              <h5 class="my-4">Connect With Us</h5>
              <a
                target="_blank"
                href="https://www.facebook.com/eparishevaonline/"
              >
                <img src="img/footer/facebook.png" class="mr-2 height" alt />
              </a>

              <a
                target="_blank"
                href="https://www.instagram.com/eparishevawestbengal?r=nametag"
              >
                <img src="img/footer/instagram.png" class="mr-2 height" alt />
              </a>

              <a
                target="_blank"
                href="https://www.linkedin.com/company/6457757/admin/"
              >
                <img src="img/footer/linkedin.png" class="mr-2 height" alt />
              </a>
              <!-- <a target="_blank" href="https://twitter.com/teka_time">
                <img src="img/footer/twitter.png" class="height" alt />
              </a> -->
            </div>
            <div class="knowsus mt-3">
              <router-link v-if="modules.contact" to="/contact"
                >Contact Us</router-link
              >
              <router-link
                v-if="modules.termsConditions"
                to="/terms-and-conditions"
                >Terms and Conditions</router-link
              >
              <router-link v-if="modules.privacyPolicy" to="/privacy-policy"
                >Privacy Policy</router-link
              >
            </div>
          </b-col>
          <b-col md="1"></b-col>
          <b-col md="3">
            <div class="mt-5 ml-n5">
              <h5 class="my-4">We Accept Payment Methods :</h5>

              <img src="img/footer/visa.png" alt />

              <img class="ml-2" width="50px" src="img/footer/paytm.jpg" alt />

              <img class="ml-2" src="img/footer/master_card.png" alt />

              <img class="ml-2" src="img/footer/google-pay.png" alt />

              <img class="ml-2" src="img/footer/phone-pe.png" alt />
              <b-navbar-nav class="ml-5 p-3">
                <b-nav-form>
                  <b-nav-text> Now We Are Available On </b-nav-text>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.eparisheva.client&hl=en_IN&gl=US"
                  >
                    <img
                      class="ml-2"
                      src="img/navbar/playstore-1.png"
                      alt
                      width="160px"
                    />
                  </a>
                </b-nav-form>
              </b-navbar-nav>
            </div>
          </b-col>
          <!-- <b-col md="4">
            <div class="mt-5 text-right payment-method-footer">
              <h5 class="my-4">Now We Are Available On</h5> -->
          <!-- <img src="img/footer/visa.png" alt />
              <img class="ml-2" width="50px" src="img/footer/paytm.jpg" alt />
              <img class="ml-2" src="img/footer/master_card.png" alt />
              <img class="ml-2" src="img/footer/google-pay.png" alt />
              <img class="ml-2" src="img/footer/phone-pe.png" alt />
              <ul class="playstore-div">
                <li>
                  <a
                    href="https://apps.apple.com/in/app/teka-customer/id1635748798"
                    target="_blank"
                  >
                    <img
                      class="ml-2"
                      src="img/navbar/bottom_app_store.png"
                      alt
                      width="160px"
                    />
                  </a>
                  <p>
                    Now We Are
                    <br />Available On
                  </p>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.eparisheva.client&hl=en_IN&gl=US"
                    target="_blank"
                  >
                    <img
                      class="ml-2"
                      src="img/navbar/playstore-1.png"
                      alt
                      width="160px"
                    />
                  </a>
                </li>
              </ul>
              <div class="footer-makeinindia">
                <b-img
                  src="img/footer/Make-in-India-Logo.jpg"
                  width="180"
                ></b-img>
              </div>
            </div> -->
          <!-- </b-col> -->
        </b-row>
        <hr class="mt-2 hr-1" />
        <b-row>
          <b-col md="12" class="text-center my-2">
            <div class="mx-3 copyright-sec">
              Copyright © 2022 eParisheva Powered by

              <a id="link-footer" href="https://nipige.com/" target="_blank"
                ><span class="nipige-color">nipige</span></a
              >.
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="teka-mobile-menu">
      <router-link to="/">
        <img src="/img/small_teka_logo.png" width="16px" alt />
        Home
      </router-link>
      <router-link to="/store">
        <b-icon icon="shop"></b-icon>Store
      </router-link>
      <router-link to="/my-order">
        <b-icon icon="cart4"></b-icon>My Orders
      </router-link>
      <router-link to="/profile">
        <b-icon icon="person-circle"></b-icon>Account
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "laylout",
  data() {
    return {
      //firstname: "",
      isLoading: false,
      selected: "product",
      options: [
        { text: "Product", value: "product" },
        { text: "Store", value: "store" },
      ],
      clickHelp: false,
      // helplineNo: "+91 9550265720",
      selectedDistance: 6000,
      distanceOptions: [
        { value: 6000, text: "Anywhere" },
        { value: 1, text: "1KM" },
        { value: 2, text: "2KM" },
        { value: 5, text: "5KM" },
        { value: 10, text: "10KM" },
        { value: 20, text: "20KM" },
        { value: 30, text: "30KM" },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    if (this.$store.state["auth"].isLoggedin) await this.fetchCart();
    // this.$nextTick(() => {
    //   this.$nuxt.$loading.start();
    //   setTimeout(() => this.$nuxt.$loading.finish(), 3000);
    // });
    await this.fetchItemsData();
    // await this.fetchStore();
  },
  methods: {
    ...mapActions({
      fetchAllItemsData: "home/fetchAllItems",
      fetchNewStoreData: "home/fetchNewStores",
    }),
    fetchStore() {
      let payload = {
        category: "SELLER",
        name: "",
        state: "",
        city: "",
        zip: "",
        street: "",
        market: "",
        lat: this.currentPosition?.Lat || 0,
        lng: this.currentPosition?.Lng || 0,
        radius: 6000,
        newlyAdded: "n",
        refresh: true,
      };
      this.fetchNewStoreData(payload);
    },
    fetchItemsData() {
      this.fetchAllItemsData();
    },
    async myOrder() {
      await this.$store.dispatch("profile/fetchOrderList");
      this.$router.push("/my-order");
    },
    updateWebsiteMode(payload) {
      this.selected = payload;
      this.$store.dispatch("core/setWebsiteMode", payload);
      this.$router.push("/");
    },
    clickFunc() {
      let oldData = this.clickHelp;
      this.clickHelp = true;
      if (oldData) {
        this.clickHelp = false;
      }
    },
    fetchByDistance() {
      let payload = {
        city: this.currentPosition.data.City,
      };
      if (this.selectedDistance === 6000) {
        payload = {
          city: 6000,
        };
      }
      this.$store.dispatch("home/fetchAllItems", payload);
      const payloadData = {
        category: "SELLER",
        name: "",
        state: "",
        city: "",
        zip: "",
        street: "",
        market: "",
        lat: this.currentPosition?.Lat || 0,
        lng: this.currentPosition?.Lng || 0,
        radius: this.selectedDistance,
        newlyAdded: "n",
        refresh: true,
      };
      this.$store.dispatch("home/fetchNewStores", payloadData);
    },
    async fetchCart() {
      if (Array.isArray(this.cartData) && this.cartData.length == 0)
        await this.$store.dispatch("checkout/fetchCartData", {
          shipFrom: "IN",
          shipTo: "IN",
          country: "IN",
        });
      this.isLoading = false;
    },
    logout() {
      //   this.$store.dispatch("auth/resetUser");
      this.$bvModal
        .msgBoxConfirm("Do you want to logout from Eparisheva?", {
          title: "Please Confirm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            localStorage.removeItem("eparisheva:access_token");
            localStorage.removeItem("eparisheva:userInfo");
            this.$store.dispatch("auth/resetUser");
            this.$swal({
              text: "See you soon! Have a Good Day.",
              type: "success",
              title: "Success",
              timer: 1100,
            });
          }
        });
      this.$router.push({ name: "Home" });
    },
  },
  computed: {
    ...mapGetters({
      modules: "availableModules",
    }),
    ...mapState({
      cartData: (state) => state.checkout.cartData,
      userInfo: (state) => state.auth.userInfo,
      currentPosition: (state) => state.location.currentPosition,
      partnerInfo: (state) => state.shopDetails.partnerInfo,
    }),
  },
};
</script>
<style>
.select-style .custom-select {
  border-radius: 50px;
  height: 43px;
  margin-left: -10px;
  width: 120px;
  font-size: 14px;
}

.dropdown-item {
  padding: 0.25rem 0.9rem;
}

.knowsus a {
  display: block;
  margin-bottom: 15px;
  color: #fff;
}

.knowsus a:hover {
  color: #055fb4;
  text-decoration: none;
}

.header-PS {
  border: 1px solid #fff;
  border-radius: 3px;
}

.playstore-div {
  margin-top: 15px;
}

.playstore-div li {
  list-style: outside none none;
}

.playstore-div li p {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 15px;
}

.primary-header {
  background: linear-gradient(#2a8750, #284397);
  position: fixed;
  color: #fff;
  top: 0;
  z-index: 2;
  width: 100%;
  display: block;
  clear: both;
}

.margin-top {
  margin-top: 30px;
}

.top-header-leftside {
  margin-bottom: 0;
  padding: 20px 0;
}

.top-header-rightside {
  padding: 13px 0;
  margin-bottom: 0;
  text-align: center;
}

.top-header-rightside > li {
  list-style: outside none none;
  display: inline-block;
  margin-right: 10px;
}

.top-header-rightside > li:last-child {
  margin-right: 0 !important;
}

.nipige-color {
  color: #fe7b08;
}

.available-onsec {
  text-align: center;
  font-size: 13px;
}

.top-header-leftside li {
  list-style: outside none none;
  display: inline-block;
  margin-right: 25px;
}

.android-iphone-icon {
  margin-top: 2px;
}

.top-header-leftside li:last-child {
  margin-right: 0 !important;
}

.height {
  height: 30px !important;
}

#link-footer {
  color: #fff;
  text-decoration: none;
}

.header-cart {
  position: relative;
}

.checkout-notification {
  background: #ff6a2e;
  position: absolute;
  top: -20px;
  right: -23px;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50px;
  margin: 0;
  font-size: 14px;
}

#btn-radios-2 {
  height: 36px !important;
}

.wishlist-header {
  margin-right: 15px;
}

.wishlist-notification {
  position: absolute;
  background: #0b7029;
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  top: -30%;
  right: 25px;
  padding: 3px;
}

.teka-mobile-menu {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 3;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  -webkit-box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: none;
}

.teka-mobile-menu a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 80px;
  padding: 8px 0px;
  border-radius: 8px;
  position: relative;
  color: #272727;
  font-size: 14px;
}

.mobile-view-header {
  display: none;
}

.teka-mobile-menu a.router-link-exact-active.router-link-active {
  color: #fe7b08;
}

.footer-makeinindia img {
  background: #fff;
  padding: 5px;
}

.checkout-notification {
  background: #fe7b08;
}

@media (max-width: 1191px) {
  .checkout-notification {
    background: #fe7b08;
    position: absolute;
    top: -12px;
    right: -18px;
    height: 18px;
    width: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 50px;
    margin: 0;
    font-size: 12px;
  }

  .top-header-rightside {
    padding: 0;
  }

  .desktop-view-header {
    display: none;
  }

  .mobile-view-header {
    display: block;
    position: sticky;
  }

  .payment-method-footer {
    text-align: left !important;
  }
  .margin-top-2 {
    background: linear-gradient(#2a8750, #284397);
  }
  .teka-mobile-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .margin-top-98.mobile-hide-margin {
    margin-top: 0 !important;
  }

  .copyright-sec {
    margin-bottom: 60px;
  }

  #helpContent {
    display: none;
  }

  /* .footer-part{
    display: none;
  } */
}
</style>
