import moduleConfig from "./terms_conditions.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./terms_conditions.module"),
    children: [
      {
        path: '/',
        name: 'terms_conditions',
        component: () => import("./views/terms_conditions"),
      },
    ],
  },
];
