import moduleConfig from "./privacy_policy.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./privacy_policy.module"),
    children: [
      {
        path: '/',
        name: 'privacy_policy',
        component: () => import("./views/privacy_policy"),
      },
    ],
  },
];
