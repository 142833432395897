export default {
  key: "address",
  basePath: "/address",
  exclude: false,
  components: [
    {
      name: "cart-address",
      component: () => import("./components/cart-address"),
    },
    {
      name: "no-address",
      component: () => import("./components/no-address"),
    },
    {
      name: "cart-address-modal",
      component: () => import("./components/cart-address-modal"),
    },
    {
      name: "add-address-modal",
      component: () => import("./components/add-address-modal"),
    },
    {
      name: "edit-address-modal",
      component: () => import("./components/edit-address-modal"),
    },
  ],
};
