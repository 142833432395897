export default {
    key: "bill_payment",
    basePath: "/bill_payment",
    exclude: false,
    components: [
      {
        name: "category",
        component: () => import("./components/category"),
      },
      {
        name: "action-transaction",
        component: () => import("./components/actionTransaction"),
      },
      {
        name: "latest-offers",
        component: () => import("./components/latestOffers"),
      },
    ],
  };