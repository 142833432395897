import moduleConfig from "./profile.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./profile.module"),
    children: [
      {
        path: "/",
        name: "profile",
        component: () => import("./views/profile"),
      },
      {
        path: "/my-order",
        name: "my-order",
        component: () => import("./views/my-order"),
      },
      {
        path: "/need-help",
        name: "need-help",
        component: () => import("./views/need-help"),
      },
      {
        path: "/need-help/:orderId",
        name: "need-help-orderId",
        component: () => import("./views/need-help"),
      },
      {
        path: "/need-help-faq",
        name: "need-help-faq",
        component: () => import("./views/need-help-faq"),
      },
      {
        path: "/need-help-faq/:orderId",
        name: "need-help-faq-orderId",
        component: () => import("./views/need-help-faq"),
      },
      {
        path: "/need-help-support-ticket",
        name: "need-help-support-ticket",
        component: () => import("./views/support-ticket"),
      },
      {
        path: "/need-help-support-ticket/:orderId",
        name: "need-help-support-ticket-orderId",
        component: () => import("./views/support-ticket"),
      },
      {
        path: "/need-help-chat-with-seller",
        name: "need-help-chat-with-seller",
        component: () => import("./views/chat-with-seller"),
      },
      {
        path: "/order-detail",
        name: "order-detail",
        component: () => import("./views/order-detail"),
      },
      {
        path: "/support-ticket",
        name: "support-ticket",
        component: () => import("./views/support-ticket"),
      },
      {
        path: ":id",
        name: "support-ticket-detail",
        component: () => import("./views/support-ticket-detail"),
      },
      {
        path: "/wallet",
        name: "wallet",
        component: () => import("./views/wallet"),
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("./views/faq"),
      },
      {
        path: "/view-address",
        name: "view-address",
        component: () => import("./views/view-address"),
      },
      {
        path: "/call-seller",
        name: "call-seller",
        component: () => import("./views/call-seller"),
      },
      {
        path: "/storeOrder",
        name: "storeOrder",
        component: () => import("./views/storeOrder"),
      },
    ],
  },
];
