import { contactEnquiry } from "./services";
export default {
  namespaced: true,
  state: {
    isLoading: false,
    ContactEnquiry: [],
  },
  actions: {
    async getContactEnquiry(_, payload) {
      let result = await contactEnquiry(payload);
      return result;
    },
  },
};
