export default {
  key: "profile",
  basePath: "/profile",
  exclude: false,
  components: [
    {
      name: "show-edit-profile",
      component: () => import("./components/show-edit-profile"),
    },
    {
      name: "my-account-detail",
      component: () => import("./components/my-account-detail"),
    },
    {
      name: "order",
      component: () => import("./components/order"),
    },
    {
      name: "need-help",
      component: () => import("./views/need-help"),
    },
    {
      name: "view-all-address",
      component: () => import("./components/view-all-address"),
    },
  ],
};
