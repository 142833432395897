export default {
  key: "items",
  basePath: "/item",
  exclude: false,
  components: [
    {
      name: "item",
      component: () => import("./components/item"),
    },
    {
      name: "cart-item",
      component: () => import("./components/cart-item"),
    },
    {
      name: "product-card",
      component: () => import("./components/product-card"),
    },
    {
      name: "order-item",
      component: () => import("./components/order-item"),
    },
  ],
};
