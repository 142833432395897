import vue from "vue";

export const fetchProductDetails = async (id) => {
  const res = await vue.axios.get(`/catalog/api/item/${id}`);
  const itemsResponse = res.data.response;
  return Array.isArray(itemsResponse) ? itemsResponse[0] : undefined;
};

export const getProductVariantDetails = async (payload) => {
  const res = await vue.axios.get(`/catalog/api/item/variation/${payload}`);
  return res.data.response[0];
};

export const addProductReview = async (payload) => {
  const res = await vue.axios.post("/feedback/product-review/create", payload);
  return res;
};

export const listProductFeedback = async (productId) => {
  const res = await vue.axios.get(`feedback/product-review/list/${productId}`);
  return res.data.data;
};

export const fetchRelatedIems = async (payload) => {
  const res = await vue.axios.post("/home/v2/list/related-items", payload);
  return res.data.response;
};

export const fetchNewStores = async (payload) => {
  const res = await vue.axios.post(`/cap/users/partner/nearby`, payload);
  return res.data;
};

export const fetchAllItems = async (payload) => {
  const res = await vue.axios.post(`/home/list/all`, payload);
  return res.data;
};
