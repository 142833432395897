import vue from "vue";

export const fetchStoreCategoryList = async (id) => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/list-provider-catalog/${id}?isCategory=y&action=filter`
  );
  return res.data.response;
};

export const fetchItemsList = async (payload) => {
  const res = await vue.axios.post(`/search/provider/search`, payload);
  return res;
};

export const getPartnerInfo = async (payload) => {
  const res = await vue.axios.get(
    `/cap/users/partner/get-preferences/${payload}`
  );
  return res.data.response;
};
