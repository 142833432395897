import vue from "vue";

const uploadImageToStorage = async (payload) => {
  const res = await vue.axios.post(`/storage/image/upload`, payload);
  return res;
};

const fetchSpecificItemsList = async (marketId) => {
  const res = await vue.axios.get(`/catalog/api/catalog/read/catalog/tree-public?market=${marketId}`);
  return res.data.response
}

 const fetchAllItems = async (payload) => {
  const res = await vue.axios.post(`/home/list/all`,payload);
  return res.data
}

const pharmacyStoreList = async (payload) => {
  const res = await vue.axios.post(`cap/users/partner/nearby?page=1`, payload);
  return res;
};

const listProductFeedback = async (productId) => {
  const res = await vue.axios.get(`feedback/product-review/list/${productId}`);
  return res.data.data;
};

const createQuote = async (payload) => {
  const res = await vue.axios.post(`/quote/api/v1/create-quote`,payload);
  return res;
};

const listQutableItem = async (payload) => {
  const res = await vue.axios.post(`/search/provider/search/items`,payload);
  return res;
};

export { uploadImageToStorage, pharmacyStoreList,fetchAllItems,fetchSpecificItemsList, listProductFeedback, createQuote,listQutableItem};
