import location from './events/location'

export default {
  key: "location",
  basePath: '/location',
  exclude: false,
  eventManager: {
    location
  },
  components: [
    {
      name: 'address',
      component: () => import('./components/location')
    },
  ]
}
